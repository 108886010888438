import logo from './logo.svg';
import './App.css';
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/header';
import HeroSection from './components/hero';
import FeaturesSection from './components/featuressection';
import About from './components/About';
import HowItWorks from './components/HowItWorks';
import Footer from './components/footer';
import Section from './components/Section';
import ContactUs from './components/ContactUs';
import Stats from './components/Stats';
import FAQ from './components/FAQ';
import FAQPreview from './components/FAQPreview';
import { FAQData } from './components/FAQ';
import CTA from './components/CTA';
import Membership from './components/Membership';
import Steps from './components/Steps';
import WhyChooseUs from './components/WhyChooseUs';
import TrustpilotReviews from './components/TrustpilotReviews';
import HowToQualify from './components/HowToQualify';
import Blog from './components/Blog';
import Conditions from './components/Conditions';
import Dispensaries from './components/Dispensaries';
import CannabisEducation from './components/CannabisEducation';
import VirginiaDispensaryLocations from './components/VirginiaDispensaryLocations';
import StrainDetail from './components/StrainDetail';
import ConditionDetail from './components/ConditionDetail';

function App() {
  return (
    <Router>
    <div className="App">
      <Header />
      <Routes>
        <Route exact path="/" element={<>
              <HeroSection />
              <Stats />
              <WhyChooseUs 
                 buttonText="Learn More" 
                 buttonLink="/how-it-works" 
                />
              <TrustpilotReviews />
              <Steps 
                  titleText="How It Works"
                  closingText="Click on the Video Walkthrough Button Below to See Our Entire Process" 
                  buttonText="Video Walkthrough" 
                  buttonLink="/how-it-works" 
                />
              <Membership />
              <CTA />
              <FAQPreview faqData={FAQData} filterTags={['home']} moreQuestionsLink="/faq" />
              </>
            } />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/how-it-works" element={<HowItWorks />} />
        <Route exact path="/contact-us" element={<ContactUs />} /> 
        <Route exact path="/how-to-qualify" element={<HowToQualify />} /> 
        <Route exact path="/faq" element={<>
          <FAQ />
          <Membership />
        </>} />
        <Route exact path="/blog" element={<Blog />} />
        <Route exact path="/conditions" element={<Conditions />} />
        <Route exact path="/conditions/:slug" element={<ConditionDetail />} />
        <Route exact path="/dispensaries" element={<Dispensaries />} />
        <Route exact path="/virginia-dispensary-locations" element={<VirginiaDispensaryLocations />} />
        <Route exact path="/cannabis-strains" element={<CannabisEducation />} />
        <Route path="/cannabis-education" element={<CannabisEducation />} />
        <Route path="/cannabis-education/:slug" element={<StrainDetail />} />
      </Routes>
     {/* <Routes>
          <Route path={["/", "/home"]} element={
            <>
              <HeroSection />
              <FeaturesSection />
            </>
          } />
        <Route path="/about" element={<About />} />
        <Route path="/how-it-works" element={<HowItWorks />} />
      </Routes>  */}
      {/* <Section
        title="Get Approved For Your Medical Marijuana Card Today!"
        description="Book your appointment today so you can receive your physician recommendation for medical marijuana and get approved for your medical marijuana card."
        mediaUrl="approved-card-image-url"
        mediaType="image"
        isHighlighted
        isReverse
      />
      <ProudMember /> */}
      <Footer />
    </div>
  </Router>
  );
}

export default App;


