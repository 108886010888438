import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { getStates } from '../config/airtable';
import './css/HeroComponent.css'

const HeroSection = () => {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedState, setSelectedState] = useState(null);

  const baseUrl = 'https://patient.medicalcardonlineva.com/register/';

  useEffect(() => {
    const loadStates = async () => {
      try {
        const records = await getStates();
        
        // Separate active and coming soon states
        const activeStates = [];
        const comingSoonStates = [];
        
        records.forEach(record => {
          const stateOption = {
            value: record.fields.Name,
            label: record.fields.Name,
            status: record.fields.Status,
            isComingSoon: record.fields.Status === 'Coming Soon'
          };
          
          if (record.fields.Status === 'Active') {
            activeStates.push(stateOption);
          } else if (record.fields.Status === 'Coming Soon') {
            comingSoonStates.push(stateOption);
          }
        });

        // Sort each array alphabetically
        activeStates.sort((a, b) => a.label.localeCompare(b.label));
        comingSoonStates.sort((a, b) => a.label.localeCompare(b.label));

        // Combine arrays with active states first
        setOptions([...activeStates, ...comingSoonStates]);
      } catch (err) {
        setError('Failed to load available states');
        console.error('Error loading states:', err);
      } finally {
        setLoading(false);
      }
    };

    loadStates();
  }, []);

  const handleStateChange = (selectedOption) => {
    setSelectedState(selectedOption);
  };

  const getButtonUrl = () => {
    if (selectedState && !selectedState.isComingSoon) {
      return `${baseUrl}?state=${encodeURIComponent(selectedState.value)}`;
    }
    return baseUrl;
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      width: '200px',
      backgroundColor: '#fff',
      border: state.isFocused ? '1px solid #08f784' : '1px solid #384e68',
      borderRadius: '20px',
      boxShadow: state.isFocused ? '0 0 5px rgba(8, 247, 132, 0.5)' : 'none',
      '&:hover': {
        borderColor: '#08f784',
      },
      cursor: 'pointer',
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: '#384e68',
      textTransform: 'uppercase',
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: '#384e68',
      '&:hover': {
        color: '#08f784',
      },
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: '#fff',
      border: '1px solid #384e68',
      borderRadius: '20px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      marginTop: '5px',
      maxHeight: '200px',
      overflowY: 'auto',
    }),
    menuList: (provided) => ({
      ...provided,
      padding: '0 0 15px 0',
      borderRadius: '20px',
    }),
    option: (provided, state) => ({
      ...provided,
      padding: '10px 20px',
      color: state.data.isComingSoon ? '#666' : '#384e68',
      fontStyle: state.data.isComingSoon ? 'italic' : 'normal',
      backgroundColor: state.isSelected ? '#4ac9f3' : '#fff',
      '&:hover': {
        backgroundColor: state.isSelected ? '#4ac9f3' : '#f0f0f0',
      },
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      '&::after': state.data.isComingSoon ? {
        content: '"Coming Soon"',
        fontSize: '12px',
        marginLeft: '8px',
        color: '#666',
      } : {},
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#384e68',
      textTransform: 'uppercase',
    }),
  };

  if (error) return <div>Error: {error}</div>;

  return (
    <div className="hero-section">
      <div className="green-cloud"></div>
      <div className="hero-content">
        <h1 className="welcome-text">WELCOME</h1>
        <h2 className="main-heading">Get Your Medical Marijuana Card Online In Minutes.</h2>
        <div className="state-selection">
          {!loading && (
            <Select 
              options={options} 
              styles={customStyles} 
              className="state-dropdown" 
              placeholder="Select State"
              onChange={handleStateChange}
              value={selectedState}
              formatOptionLabel={({ label, isComingSoon }) => (
                <div>{label}</div>
              )}
            />
          )}
          <a href={getButtonUrl()}>
            <button className="get-card-button">Get Your Card</button>
          </a>
        </div>
        <div className="hero-benefits">
          <div className="hero-benefit-item">
            <img className="check-icon" src="/images/tick.png" alt="icon-1" />
            Affordable And Simple Pricing. Get Approved Or Your Money Back.
          </div>
          <div className="hero-benefit-item">
            <img className="check-icon" src="/images/tick.png" alt="icon-1" />
            Our Platform Connects You To A Network Of Doctors To Get Certified In Minutes.
          </div>
        </div>
      </div>
      <div className="hero-image">
        <img src={'/images/banner-hand.png'} alt="Medical Card" />
      </div>
    </div>
  );
};

export default HeroSection;