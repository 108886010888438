const BASE_ID = process.env.REACT_APP_AIRTABLE_BASE_ID;
const TABLE_ID = 'tblQHVtF6zSNxT0jT';
const ACCESS_TOKEN = process.env.REACT_APP_AIRTABLE_ACCESS_TOKEN;

export const getStates = async () => {
  // Debug logging
  console.log('Making Airtable request with:');
  console.log('Base ID:', BASE_ID);
  console.log('Table ID:', TABLE_ID);
  console.log('Token exists:', !!ACCESS_TOKEN);
  console.log('Token length:', ACCESS_TOKEN?.length);

  const url = `https://api.airtable.com/v0/${BASE_ID}/${TABLE_ID}?maxRecords=100`;
  console.log('Request URL:', url);

  try {
    const headers = {
      'Authorization': `Bearer ${ACCESS_TOKEN}`,
      'Content-Type': 'application/json',
    };
    console.log('Request headers:', headers);

    const response = await fetch(url, {
      method: 'GET',
      headers: headers,
    });

    console.log('Response status:', response.status);
    
    if (!response.ok) {
      const errorText = await response.text();
      console.error('Error response:', errorText);
      throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
    }

    const data = await response.json();
    return data.records;
  } catch (error) {
    console.error('Error fetching states:', error);
    throw error;
  }
};

export default { getStates }; 