import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import useFetch from '../hooks/useFetch';
import './css/Conditions.css';

const Conditions = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOrder, setSortOrder] = useState('A-Z');
  const [filterValue, setFilterValue] = useState('All');
  
  const { data: conditions, loading, error } = useFetch('/wordpress/wp-json/wp/v2/condition?per_page=100&_embed');

  if (loading) return <div className="loading">Loading conditions...</div>;
  if (error) return <div className="error">Error: {error.message}</div>;

  const filteredConditions = conditions
    ?.filter(condition => {
      const matchesSearch = condition.title.rendered.toLowerCase().includes(searchTerm.toLowerCase());
      const matchesFilter = filterValue === 'All' || condition.acf.tags.includes(filterValue);
      return matchesSearch && matchesFilter;
    })
    .sort((a, b) => {
      if (sortOrder === 'A-Z') {
        return a.title.rendered.localeCompare(b.title.rendered);
      }
      return b.title.rendered.localeCompare(a.title.rendered);
    });

  return (
    <div className="conditions-container">
      <div className="conditions-content">
        <h1>Conditions</h1>
        <h2>All Medical Conditions Can Qualify for Medical Marijuana in Virginia!</h2>
        
        <ul className="conditions-intro">
          <li>In the state of Virginia ANY diagnosed medical condition can qualify a patient for a medical marijuana card.</li>
          <li>Our physician or his team of providers can diagnose your medical condition and issue a medical cannabis certificate immediately.</li>
          <li>Below is a list of medical conditions that is commonly treated with medical marijuana.</li>
        </ul>

        <div className="conditions-filters">
          <select 
            value={filterValue} 
            onChange={(e) => setFilterValue(e.target.value)}
          >
            <option value="All">All</option>
            {/* Add your filter options here */}
          </select>

          <div className="sort-container">
            <span>Sort:</span>
            <button 
              className={sortOrder === 'A-Z' ? 'active' : ''}
              onClick={() => setSortOrder('A-Z')}
            >
              A-Z
            </button>
          </div>

          <input
            type="text"
            placeholder="Search By Condition"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        <div className="blog-posts">
          {filteredConditions?.map(condition => (
            <div key={condition.id} className="blog-card">
              {condition._embedded?.['wp:featuredmedia'] && (
                <img 
                  src={condition._embedded['wp:featuredmedia'][0].source_url}
                  alt={condition.title.rendered}
                  className="blog-image"
                />
              )}
              <div className="blog-content">
                <h2 dangerouslySetInnerHTML={{ __html: condition.title.rendered }} />
                <div 
                  className="condition-excerpt"
                  dangerouslySetInnerHTML={{ 
                    __html: condition.content.rendered
                      .replace(/<[^>]*>/g, '')
                      .split('.')
                      .slice(0, 2)
                      .join('.') + '...'
                  }} 
                />
                <a href={`/conditions/${condition.slug}`} className="read-more">Read More</a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Conditions;
