import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import useFetch from '../hooks/useFetch.js';
import './css/Blog.css';

const StrainDetail = () => {
  const { slug } = useParams();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState('overview');
  const { data: strains, loading, error } = useFetch(
    `/wordpress/wp-json/wp/v2/cannabis-strain?slug=${slug}&_embed&acf_format=standard`
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  if (loading) return <div className="loading"><p>Loading strain details...</p></div>;
  if (error) return <div className="error"><p>Error: {error.message}</p></div>;
  if (!strains || strains.length === 0) return <div className="no-content"><p>Strain not found.</p></div>;

  const strain = strains[0];
  const { acf } = strain;

  const renderTabContent = () => {
    switch (activeTab) {
      case 'overview':
        return (
          <div className="tab-content">
            <div className="strain-overview-grid">
              <div className="cannabinoids-section">
                <h2>Cannabinoids</h2>
                <p>THC: {acf.thc_level}</p>
                <p>CBD: {acf.cbd_level}</p>
              </div>

              <div className="strain-types-section">
                <h2>Strain Types</h2>
                <div dangerouslySetInnerHTML={{ __html: acf.strain_types }} />
              </div>

              <div className="terpenes-section">
                <h2>Terpenes</h2>
                <div dangerouslySetInnerHTML={{ __html: acf.terpenes }} />
              </div>
            </div>

            <div className="flavonoids-section">
              <h2>Flavonoids</h2>
              <div dangerouslySetInnerHTML={{ __html: acf.flavonoids }} />
            </div>
          </div>
        );
      case 'effects':
        return (
          <div className="tab-content">
            <div className="effects-grid">
              <div className="positive-effects">
                <h2>{strain.title.rendered} has been reported to have the following effects:</h2>
                <ul className="effects-list">
                  {typeof acf.effects === 'string' 
                    ? acf.effects.split(',').map((effect, index) => (
                        <li key={index}>{effect.trim()}</li>
                      ))
                    : Array.isArray(acf.effects)
                      ? acf.effects.map((effect, index) => (
                          <li key={index}>{effect}</li>
                        ))
                      : null
                  }
                </ul>
              </div>
              
              {acf.adverse_effects && (
                <div className="adverse-effects">
                  <h2>The following adverse effects have also been reported:</h2>
                  <ul className="effects-list">
                    {typeof acf.adverse_effects === 'string'
                      ? acf.adverse_effects.split(',').map((effect, index) => (
                          <li key={index}>{effect.trim()}</li>
                        ))
                      : Array.isArray(acf.adverse_effects)
                        ? acf.adverse_effects.map((effect, index) => (
                            <li key={index}>{effect}</li>
                          ))
                        : null
                    }
                  </ul>
                </div>
              )}
            </div>
          </div>
        );
      case 'medical':
        return (
          <div className="tab-content">
            <div dangerouslySetInnerHTML={{ __html: acf.medical_applications_overview }} />
          </div>
        );
      case 'dosing':
        return (
          <div className="tab-content">
            <div dangerouslySetInnerHTML={{ __html: acf.dosing }} />
          </div>
        );
      case 'growing':
        return (
          <div className="tab-content">
            <div dangerouslySetInnerHTML={{ __html: acf.growing_overview }} />
            <div className="growing-details-grid">
              <div className="height-section">
                <h2>Height</h2>
                <div dangerouslySetInnerHTML={{ __html: acf.growing_height }} />
              </div>
              
              <div className="humidity-temp-section">
                <h2>Humidity and Temperature</h2>
                <div dangerouslySetInnerHTML={{ __html: acf.growing_humidity_temp }} />
              </div>
              
              <div className="flowering-section">
                <h2>Flowering</h2>
                <div dangerouslySetInnerHTML={{ __html: acf.growing_flowering }} />
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="strain-detail">
      <h1 className="strain-title" dangerouslySetInnerHTML={{ __html: strain.title.rendered }} />
      
      <div className="strain-header">
        {strain._embedded?.['wp:featuredmedia'] && (
          <div className="strain-image-container">
            <img 
              src={strain._embedded['wp:featuredmedia'][0].source_url}
              alt={strain.title.rendered}
              className="strain-featured-image"
            />
          </div>
        )}
        <div className="strain-intro-content" dangerouslySetInnerHTML={{ __html: strain.content.rendered }} />
      </div>

      <div className="strain-tabs">
        <button 
          className={`tab-button ${activeTab === 'overview' ? 'active' : ''}`}
          onClick={() => setActiveTab('overview')}
        >
          Overview
        </button>
        <button 
          className={`tab-button ${activeTab === 'effects' ? 'active' : ''}`}
          onClick={() => setActiveTab('effects')}
        >
          Effects
        </button>
        <button 
          className={`tab-button ${activeTab === 'medical' ? 'active' : ''}`}
          onClick={() => setActiveTab('medical')}
        >
          Medical Applications
        </button>
        <button 
          className={`tab-button ${activeTab === 'dosing' ? 'active' : ''}`}
          onClick={() => setActiveTab('dosing')}
        >
          Dosing
        </button>
        <button 
          className={`tab-button ${activeTab === 'growing' ? 'active' : ''}`}
          onClick={() => setActiveTab('growing')}
        >
          Growing
        </button>
      </div>

      {renderTabContent()}
    </div>
  );
};

export default StrainDetail;
