import React, { useEffect } from 'react';
import { useParams, Link, useLocation } from 'react-router-dom';
import useFetch from '../hooks/useFetch';
import './css/Conditions.css';

const ConditionDetail = () => {
  const { slug } = useParams();
  const location = useLocation();
  const { data: allConditions, loading, error } = useFetch(
    '/wordpress/wp-json/wp/v2/condition?per_page=100'
  );
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  
  if (loading) return <div className="loading">Loading condition details...</div>;
  if (error) return <div className="error">Error: {error.message}</div>;
  if (!allConditions || allConditions.length === 0) return <div>Condition not found</div>;

  const currentIndex = allConditions.findIndex(c => c.slug === slug);
  const condition = allConditions[currentIndex];
  const prevCondition = currentIndex > 0 ? allConditions[currentIndex - 1] : null;
  const nextCondition = currentIndex < allConditions.length - 1 ? allConditions[currentIndex + 1] : null;

  if (!condition) return <div>Condition not found</div>;

  return (
    <div className="condition-detail">
      <h1 className="condition-title" dangerouslySetInnerHTML={{ __html: condition.title.rendered }} />
      
      <div className="condition-content" dangerouslySetInnerHTML={{ __html: condition.content.rendered }} />
      
      <div className="cta-container">
        <a href="https://cannabismdtelemed.com/appointments" className="ctabutton">Book Appointment</a>
      </div>

      {condition.acf.tags && (
        <div className="condition-tags">
          <h3>Related Tags:</h3>
          <div className="tags-list">
            {Array.isArray(condition.acf.tags) 
              ? condition.acf.tags.map(tag => (
                  <span key={tag} className="tag">{tag}</span>
                ))
              : typeof condition.acf.tags === 'string'
                ? condition.acf.tags.split(',').map(tag => (
                    <span key={tag.trim()} className="tag">{tag.trim()}</span>
                  ))
                : null
            }
          </div>
        </div>
      )}

      <div className="navigation-links">
        {prevCondition && (
          <Link to={`/conditions/${prevCondition.slug}`} className="nav-link prev">
            <span className="arrow">←</span>
            <span className="nav-title" dangerouslySetInnerHTML={{ __html: prevCondition.title.rendered }} />
          </Link>
        )}
        {nextCondition && (
          <Link to={`/conditions/${nextCondition.slug}`} className="nav-link next">
            <span className="nav-title" dangerouslySetInnerHTML={{ __html: nextCondition.title.rendered }} />
            <span className="arrow">→</span>
          </Link>
        )}
      </div>
    </div>
  );
};

export default ConditionDetail;
